@font-face {
  font-family: 'PangMenZhengDao';
  src: url('../fonts/pmzd20.ttf');
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('../fonts/Poppins-Medium.ttf');
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('../fonts/Poppins-SemiBold.ttf');
}

@font-face {
  font-family: 'iconfont';
  src: url('../fonts/iconfont.woff2') format('woff2'), url('../fonts/iconfont.woff') format('woff'),
    url('../fonts/iconfont.ttf') format('truetype');
}

.iconfont {
  font-family: 'iconfont' !important;
  font-size: 14px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-drop-down:before {
  content: '\e64f';
}

.icon-add:before {
  content: '\e655';
}

.icon-page-prev:before {
  content: '\e653';
}

.icon-page-next:before {
  content: '\e654';
}

.icon-go-up:before {
  content: '\e651';
}

.icon-go-down:before {
  content: '\e652';
}

.icon-text-logo:before {
  content: '\e650';
}

.icon-switch:before {
  content: '\e64e';
}

.icon-dark:before {
  content: '\e64b';
}

.icon-light:before {
  content: '\e64c';
}

.icon-world:before {
  content: '\e64d';
}

.icon-LessGas:before {
  content: '\e644';
}

.icon-Telegram:before {
  content: '\e645';
}

.icon-GitHub:before {
  content: '\e646';
}

.icon-Swapfaster:before {
  content: '\e647';
}

.icon-delete:before {
  content: '\e648';
}

.icon-medium:before {
  content: '\e649';
}

.icon-twitter-circle-fill:before {
  content: '\e64a';
}

.icon-dot:before {
  content: '\e640';
}

.icon-search:before {
  content: '\e641';
}

.icon-close:before {
  content: '\e642';
}

.icon-refresh:before {
  content: '\e63e';
}

.icon-arrow-down:before {
  content: '\e63c';
}

.icon-loading:before {
  content: '\e63d';
}

.icon-alert:before {
  content: '\e63f';
}

.icon-close-lg:before {
  content: '\e639';
}

.icon-helper:before {
  content: '\e63b';
}

.icon-arrow-left:before {
  content: '\e634';
}

.icon-history:before {
  content: '\e635';
}

.icon-chart:before {
  content: '\e636';
}

.icon-time:before {
  content: '\e637';
}

.icon-chart-close:before {
  content: '\e638';
}

.icon-setting:before {
  content: '\e63a';
}

.icon-block-list:before {
  content: '\e65b';
}

.icon-list:before {
  content: '\e65c';
}

.icon-calculator:before {
  content: '\e658';
}

.icon-point-arrow-right:before {
  content: '\e657';
}

.icon-arrow-up:before {
  content: '\e659';
}

.icon-arrow-down:before {
  content: '\e65a';
}

.icon-helper:before {
  content: '\e656';
}

.icon-arrow-dot-left:before {
  content: '\e657';
}

.icon-calculator:before {
  content: '\e658';
}

.icon-dropdown:before {
  content: '\e65a';
}

.icon-close-up:before {
  content: '\e659';
}

.icon-list:before {
  content: '\e65c';
}

.icon-overview:before {
  content: '\e65b';
}

.icon-edit-fill:before {
  content: '\e65d';
}

.icon-faq-close-up:before {
  content: '\e65f';
}

.icon-faq-dropdown:before {
  content: '\e65e';
}

.icon-cup:before {
  content: '\e661';
}

.icon-running-man:before {
  content: '\e660';
}
